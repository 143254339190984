import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import userService from '../../services/UserServices';
import Terms from '../Terms/Terms';
import { Term } from '../../redux/types/terms';
import { userActions } from '../../redux/slices/userSlice';
import termsService from '../../services/TermsServices';
import { Box, TextField, Button, Drawer } from '@mui/material';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useNavigate } from 'react-router-dom';

const UserRegister: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const RUT = params.get('rut') || '';
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState('569');
  const [phoneError, setPhoneError] = useState('');
  const [business, setBusiness] = useState('');
  const [position, setPosition] = useState('');
  const [open, setOpen] = useState(false);
  const [thrombosis, setThrombosis] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [terms, setTerms] = useState<Term | null>(null);
  const [accept, setAccept] = useState(false);
  const { token } = useSelector((state: RootState) => state.business);
  useEffect(() => {
    termsService.getTerms().then((response) => setTerms(response));
    // changeRUT();
  }, []);

  const changeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(event.target.value);
  };
  const changeLastName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLastName(event.target.value);
  };

  const register = async () => {
    const response = await userService.createUser(
      {
        rut: RUT,
        first_name: name,
        last_name: lastName,
        diabetes: false,
        heart_diseases: false,
        other_diseases: `${business} - ${position}`,
        thrombosis,
        business_id: 1,
        phone: null,
        email: null,
      },
      'M1-EB0',
      terms?.id || NaN,
    );
    if (response.user) {
      const { user } = response;
      user.token = response.token;
      if (token !== null && token !== undefined) {
        user.mode = 'active-in';
        user.redirect = 'charlie';
        dispatch(userActions.login(user));
      } else {
        user.redirect = 'charlie';
        dispatch(userActions.login(user));
      }
    } else {
      setSubmitError(response.error);
    }
  };
  const login = () => {
    navigate('/login');
  };
  const changePhone = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex = /^[0-9\b]+$/;
    if (
      event.target.value === '' ||
      (regex.test(event.target.value) && event.target.value.slice(0, 3) === '569' && event.target.value.length <= 11)
    ) {
      setPhone(event.target.value);
      const response = await userService.phoneIsRegistered(event.target.value);
      if (event.target.value.length === 11 && response.user === null) {
        setPhoneError('');
      } else if (event.target.value.length === 11 && response.user !== null) {
        setPhoneError('El telefono ingresado ya está registrado');
      } else {
        setPhoneError('Debe tener 11 digitos');
      }
    } else if (event.target.value.slice(0, 3) !== '569') {
      setPhoneError('Codigo de area invalido');
    } else if (event.target.value.length <= 10) {
      setPhoneError('Debe tener 11 digitos');
    } else if (event.target.value.length !== 12) {
      setPhoneError('Error al ingresar el número');
    }
  };
  const changeEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(event.target.value);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(event.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Email inválido');
    }
  };

  return (
    <Box sx={{ maxWidth: '100vw', minWidth: '310px', display: 'inline-block', padding: '5%' }}>
      <SwipeableViews disabled index={0}>
        <Box>
          <Button onClick={login} size="small" sx={{ marginY: '2%', fontWeight: 'bold' }} variant="text">
            {'← Atras'}
          </Button>
          <TextField
            value={name}
            onChange={changeName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Nombre"
            variant="outlined"
          />
          <TextField
            value={lastName}
            onChange={changeLastName}
            sx={{ width: '100%', marginY: '2%' }}
            label="Apellido"
            variant="outlined"
          />
          <TextField
            sx={{ width: '100%', marginY: '2%' }}
            label="Telefono celular"
            variant="outlined"
            value={phone}
            inputProps={{ inputMode: 'numeric' }}
            onChange={changePhone}
            error={phoneError !== ''}
            helperText={phoneError}
          />
          <TextField
            value={email}
            onChange={changeEmail}
            sx={{ width: '100%', marginY: '2%' }}
            label="Email"
            variant="outlined"
            error={emailError !== ''}
            helperText={emailError}
          />
          <TextField
            value={business}
            onChange={(event) => setBusiness(event.target.value)}
            sx={{ width: '100%', marginY: '2%' }}
            label="Empresa"
            variant="outlined"
          />
          <TextField
            value={position}
            onChange={(event) => setPosition(event.target.value)}
            sx={{ width: '100%', marginY: '2%' }}
            label="Cargo"
            variant="outlined"
          />
          <FormGroup sx={{ marginBottom: '0px' }}>
            <p>
              Marca la casilla si has sido <b>diagnosticado por un médico</b> con alguna de las siguientes enfermedades:
            </p>
            <FormControlLabel
              onChange={() => setThrombosis(thrombosis === false)}
              control={<Checkbox checked={thrombosis} />}
              label="Antencedentes de Trombosis"
            />
          </FormGroup>
          <Box>
            <Drawer open={open} onClose={() => setOpen(false)}>
              <Terms content={terms?.content || ''} />
              <Box sx={{ margin: '24px' }}>
                <Button
                  size="large"
                  sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
                  variant="contained"
                  onClick={() => setOpen(false)}>
                  Cerrar
                </Button>
              </Box>
            </Drawer>
            <FormControlLabel
              sx={{ marginY: '12px' }}
              onChange={() => setAccept(accept === false)}
              control={<Checkbox checked={accept} />}
              label="He leido y acepto los términos y condiciones de uso"
            />
            <Button sx={{ textDecoration: 'underline', fontWeight: 'bold' }} onClick={() => setOpen(true)}>
              (VER TERMINOS Y CONDICIONES)
            </Button>
          </Box>
          <Button
            onClick={register}
            size="large"
            sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
            disabled={RUT === '' || name === '' || lastName === '' || accept === false}
            variant="contained">
            EMPEZAR
          </Button>
          {submitError !== '' && <p>{submitError}</p>}
        </Box>
      </SwipeableViews>
    </Box>
  );
};

export default UserRegister;
