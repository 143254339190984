import React, { FC, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import businessService from '../../services/BusinessServices';
import { businessActions } from '../../redux/slices/businessSlice';
import { Box, TextField, Button } from '@mui/material';
import logo_vrt from './../../images/logo_vrt.png';

const CompanyLogin: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loginError, setLoginError] = useState('');
  const changeEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Formato no válido');
    }
    setEmail(event.target.value.toLocaleLowerCase());
  };
  const changePassword = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
  };
  const login = async () => {
    try {
      const response = await businessService.loginAdmin({ email, password });
      if (response) {
        const { business } = response;
        business.token = response.token;
        dispatch(businessActions.login(business));
        navigate('/login');
      } else {
        setLoginError('Contraseña y/o email incorrectos, intenta denuevo');
        setPassword('');
      }
    } catch (error) {
      setLoginError('Contraseña y/o email incorrectos, intenta denuevo');
      setPassword('');
    }
  };
  const register = () => {
    navigate('/login');
  };
  return (
    <Box sx={{ minWidth: '320px', display: 'inline-block', padding: '5%' }}>
      <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo_vrt} height={'100px'} />
      </Box>
      <Box sx={{ width: '100%', marginY: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h4 className="App-logo-text">Makana Empresas</h4>
      </Box>
      <TextField
        sx={{ width: '100%', marginY: '2%' }}
        label="Email"
        variant="outlined"
        value={email}
        onChange={changeEmail}
        error={emailError !== ''}
        helperText={emailError}
        inputProps={{ inputMode: 'email' }}
      />
      <TextField
        sx={{ width: '100%', marginY: '2%' }}
        label="Contraseña"
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={changePassword}
        error={loginError !== ''}
        helperText={loginError}
      />
      <Button
        onClick={login}
        size="large"
        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '50px' }}
        variant="contained">
        INICIAR SESION
      </Button>
      <Button
        type="button"
        onClick={register}
        size="large"
        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold' }}
        variant="text">
        Entrar como usuario
      </Button>
    </Box>
  );
};

export default CompanyLogin;
