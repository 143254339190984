import React, { FC, useState, useEffect } from 'react';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import recomendador from './tree';
import recomendador_safe from './tree_safe';
import { Box, Button, Fab, Typography, Grow, Drawer, IconButton } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import SwipeableViews from 'react-swipeable-views';
import MenuCategory from '../HomeScreenComponents/Category';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import CloseIcon from '@mui/icons-material/Close';
import LensIcon from '@mui/icons-material/Lens';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BodyBackMap from './BackBodyMap';

const Recommender: FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const [query, setQuery] = useState<any>();
  const [objective, setObjective] = useState('');
  const [step, setStep] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [l, setL] = useState('');
  const [a, setA] = useState('');
  const [i, setI] = useState('');
  const [t, setT] = useState('');

  useEffect(() => {
    if (user.thrombosis === true || user.heart_diseases === true) {
      setQuery(recomendador_safe);
    } else {
      setQuery(recomendador);
    }
  }, []);

  const updateQuery = (e: any) => {
    if (e.target.name === 'objective') {
      setObjective(e.target.value);
      if (e.target.value === 'molestias' || e.target.value === 'hinchazon' || e.target.value === 'tension') {
        setQuery(query[e.target.value][l]);
        if (e.target.value === 'tension' || e.target.value === 'hinchazon') {
          setShowResults(true);
        }
      } else if (e.target.value === 'cansancio') {
        setQuery(query[e.target.value]);
      } else {
        setQuery(query[e.target.value]);
        setShowResults(true);
      }
    } else if (e.target.name === 's') {
      setQuery(query[e.target.value][l]);
      setShowResults(true);
    } else if (e.target.name === 'a') {
      setA(e.target.value);
      setQuery(query[e.target.value]);
      setStep(1);
    } else if (e.target.name === 'i') {
      setI(e.target.value);
      if (e.target.value === 'i1') {
        setQuery(query['i1']);
        setShowResults(true);
      } else if (
        t === 't1' ||
        ['l2', 'l3', 'l4', 'l7', 'l8', 'l9'].includes(l) === true ||
        (a === 'a1' && ['i2', 'i3'].includes(e.target.value) === true) === true
      ) {
        setStep(2);
      } else {
        setQuery(query[e.target.value]);
        setShowResults(true);
      }
    } else if (e.target.name === 't') {
      setT(e.target.value);
      if (e.target.value === 't1') {
        if (query[`${i}h1t1`] !== undefined) {
          setQuery(query[`${i}h1t1`]);
        } else if (query[`${i}h1t0`] !== undefined) {
          setQuery(query[`${i}h1t0`]);
        } else {
          setQuery(query[i]);
        }
        setShowResults(true);
      } else {
        setStep(3);
      }
    } else if (e.target.name === 'h') {
      if (query[`${i}${e.target.value}t0`] !== undefined) {
        setQuery(query[`${i}${e.target.value}t0`]);
      } else if (query[`${i}${e.target.value}t1`] !== undefined) {
        setQuery(query[`${i}${e.target.value}t1`]);
      } else {
        setQuery(query[i]);
      }
      setShowResults(true);
    }
  };

  const reset = () => {
    setQuery(recomendador);
    setObjective('');
    setStep(0);
    setShowResults(false);
    setA('');
    setI('');
    setT('');
  };

  const handleClick = (event: any) => {
    setL(event.l);
  };

  return (
    <Box>
      <Box sx={{ maxWidth: { xs: '300px', sm: '100%' }, marginX: '10vw' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ display: 'inline-block', minWidth: '140px', height: '75vh', marginY: '0px', paddingY: '0px' }}>
            <BodyBackMap onClick={handleClick} />
          </Box>
          <Box
            sx={{
              display: 'flex-wrap',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Grow in={l !== ''}>
              <Box
                sx={{
                  display: l !== '' ? 'flex' : 'none',
                  transition: 'all 0.5s linear',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Fab
                  onClick={() => updateQuery({ target: { id: 'molestias', value: 'molestias', name: 'objective' } })}
                  color="primary"
                  aria-label="add"
                  size="small">
                  <LocalHospitalIcon />
                </Fab>
                <Typography sx={{ fontSize: '12px' }}>Molestia</Typography>
              </Box>
            </Grow>
            <Grow in={l !== '' && l !== 'l5' && l !== 'l8'}>
              <Box
                sx={{
                  display: l !== '' && l !== 'l5' && l !== 'l8' ? 'flex' : 'none',
                  transition: 'all 0.5s linear',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Fab
                  onClick={() => updateQuery({ target: { id: 'tension', value: 'tension', name: 'objective' } })}
                  color="primary"
                  aria-label="add"
                  size="small">
                  <BoltIcon />
                </Fab>
                <Typography sx={{ fontSize: '12px' }}>Tensión</Typography>
              </Box>
            </Grow>
            <Grow in={l !== '' && l !== 'l1' && l !== 'l5' && l !== 'l6'}>
              <Box
                sx={{
                  display: l !== '' && l !== 'l1' && l !== 'l5' && l !== 'l6' ? 'flex' : 'none',
                  transition: 'all 0.5s linear',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Fab
                  onClick={() => updateQuery({ target: { id: 'hinchazon', value: 'hinchazon', name: 'objective' } })}
                  color="primary"
                  aria-label="add"
                  size="small">
                  <CrisisAlertIcon />
                </Fab>
                <Typography sx={{ fontSize: '12px' }}>Hinchazón</Typography>
              </Box>
            </Grow>
            <Grow in={l !== '' && (l === 'l2' || l === 'l3' || l === 'l6' || l === 'l7')} exit>
              <Box
                sx={{
                  display: 'flex',
                  height: l !== '' && (l === 'l2' || l === 'l3' || l === 'l6' || l === 'l7') ? '58px' : '0px',
                  transition: 'height 1.5s linear',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Fab
                  onClick={() => updateQuery({ target: { id: 'cansancio', value: 'cansancio', name: 'objective' } })}
                  color="primary"
                  aria-label="add"
                  size="small">
                  <BatteryAlertIcon />
                </Fab>
                <Typography sx={{ fontSize: '12px' }}>Cansancio</Typography>
              </Box>
            </Grow>
            <Grow in={l !== ''}>
              <Box
                sx={{
                  display: l !== '' ? 'flex' : 'none',
                  transition: 'all 0.5s linear',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Fab
                  onClick={() => updateQuery({ target: { id: 'relajacion', value: 'relajacion', name: 'objective' } })}
                  color="primary"
                  aria-label="add"
                  size="small">
                  <AutoAwesomeIcon />
                </Fab>
                <Typography sx={{ fontSize: '12px' }}>Relajación</Typography>
              </Box>
            </Grow>
          </Box>
        </Box>
        <Drawer sx={{ zIndex: '10100', borderRadius: '200px' }} anchor="bottom" open={objective !== ''} onClose={reset}>
          <div className="menu__header">
            <h2 className="App-logo-text">{objective.toLocaleUpperCase()}</h2>
            <IconButton onClick={reset} color="primary" aria-label="close-menu" component="label">
              <CloseIcon />
            </IconButton>
          </div>
          {showResults === false && (
            <SwipeableViews disabled index={step}>
              <Box sx={{ paddingX: '5vw' }}>
                {objective === 'cansancio' && (
                  <Box>
                    <h2>¿Qué tan cansado te sientes?</h2>
                    <Button
                      sx={{
                        width: '100%',
                        marginY: '2%',
                        fontWeight: 'bold',
                        borderRadius: '24px',
                        backgroundColor: '#8FA7FF',
                      }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      endIcon={<Battery6BarIcon />}
                      name="s"
                      value="s0">
                      Leve
                    </Button>
                    <Button
                      sx={{
                        width: '100%',
                        marginY: '2%',
                        fontWeight: 'bold',
                        borderRadius: '24px',
                        backgroundColor: '#3A6CFA',
                      }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      endIcon={<Battery3BarIcon />}
                      name="s"
                      value="s0">
                      Moderado
                    </Button>
                    <Button
                      sx={{
                        width: '100%',
                        marginY: '2%',
                        fontWeight: 'bold',
                        borderRadius: '24px',
                        backgroundColor: '#003BAF',
                      }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      endIcon={<Battery1BarIcon />}
                      name="s"
                      value="s0">
                      Muy cansado
                    </Button>
                  </Box>
                )}
                {objective === 'molestias' && (
                  <Box>
                    <h2>¿Hace cuánto presentas la molestia?</h2>
                    <Button
                      sx={{
                        width: '100%',
                        marginY: '2%',
                        fontWeight: 'bold',
                        borderRadius: '24px',
                        backgroundColor: '#8FA7FF',
                      }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      name="a"
                      value="a1">
                      Menos de una semana
                    </Button>
                    <Button
                      sx={{
                        width: '100%',
                        marginY: '2%',
                        fontWeight: 'bold',
                        borderRadius: '24px',
                        backgroundColor: '#3A6CFA',
                      }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      name="a"
                      value="a2">
                      Entre una semana y un mes
                    </Button>
                    <Button
                      sx={{
                        width: '100%',
                        marginY: '2%',
                        fontWeight: 'bold',
                        borderRadius: '24px',
                        backgroundColor: '#003BAF',
                      }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      name="a"
                      value="a3">
                      Entre uno y dos meses
                    </Button>
                    <Button
                      sx={{
                        width: '100%',
                        marginY: '2%',
                        fontWeight: 'bold',
                        borderRadius: '24px',
                        backgroundColor: '#00287D',
                      }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      name="a"
                      value="a4">
                      Más de dos meses
                    </Button>
                  </Box>
                )}
              </Box>
              <Box sx={{ paddingX: '5vw' }}>
                <h2>¿Cuál es la intensidad de ese malestar?</h2>
                <Button
                  sx={{
                    width: '100%',
                    marginY: '2%',
                    fontWeight: 'bold',
                    borderRadius: '24px',
                    '& .MuiSvgIcon-root': { fontSize: '0.5rem' },
                    backgroundColor: '#8FA7FF',
                  }}
                  variant="contained"
                  onClick={(event: any) => updateQuery(event)}
                  endIcon={<LensIcon />}
                  name="i"
                  value="i1">
                  Leve
                </Button>
                <Button
                  sx={{
                    width: '100%',
                    marginY: '2%',
                    fontWeight: 'bold',
                    borderRadius: '24px',
                    '& .MuiSvgIcon-root': { fontSize: '1.0rem' },
                    backgroundColor: '#3A6CFA',
                  }}
                  variant="contained"
                  onClick={(event: any) => updateQuery(event)}
                  endIcon={<LensIcon />}
                  name="i"
                  value="i2">
                  Media
                </Button>
                <Button
                  sx={{
                    width: '100%',
                    marginY: '2%',
                    fontWeight: 'bold',
                    borderRadius: '24px',
                    '& .MuiSvgIcon-root': { fontSize: '1.5rem', marginLeft: '10px' },
                    backgroundColor: '#003BAF',
                  }}
                  endIcon={<LensIcon />}
                  variant="contained"
                  onClick={(event: any) => updateQuery(event)}
                  name="i"
                  value="i3">
                  Alta
                </Button>
              </Box>
              <Box sx={{ paddingX: '5vw' }}>
                {a === 'a1' && ['i2', 'i3'].includes(i) === true ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                    <h2>¿Es producto de un golpe, caída o torcedura?</h2>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px', width: '100%' }}>
                      <Button
                        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '24px' }}
                        variant="contained"
                        onClick={(event: any) => updateQuery(event)}
                        name="t"
                        value="t1">
                        Si
                      </Button>
                      <Button
                        sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '24px' }}
                        variant="outlined"
                        onClick={(event: any) => updateQuery(event)}
                        name="t"
                        value="t0">
                        No
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  (t === 't1' || ['l2', 'l3', 'l4', 'l7', 'l8', 'l9'].includes(l) === true) && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                      <h2>¿Hay presencia de hinchazón en la zona?</h2>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px', width: '100%' }}>
                        <Button
                          sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '24px' }}
                          variant="contained"
                          onClick={(event: any) => updateQuery(event)}
                          name="h"
                          value="h1">
                          Si
                        </Button>
                        <Button
                          sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '24px' }}
                          variant="outlined"
                          onClick={(event: any) => updateQuery(event)}
                          name="h"
                          value="h0">
                          No
                        </Button>
                      </Box>
                    </Box>
                  )
                )}
              </Box>
              <Box sx={{ paddingX: '5vw' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                  <h2>¿Hay presencia de hinchazón en la zona?</h2>
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px', width: '100%' }}>
                    <Button
                      sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '24px' }}
                      variant="contained"
                      onClick={(event: any) => updateQuery(event)}
                      name="h"
                      value="h1">
                      Si
                    </Button>
                    <Button
                      sx={{ width: '100%', marginY: '2%', fontWeight: 'bold', borderRadius: '24px' }}
                      variant="outlined"
                      onClick={(event: any) => updateQuery(event)}
                      name="h"
                      value="h0">
                      No
                    </Button>
                  </Box>
                </Box>
              </Box>
            </SwipeableViews>
          )}
          <Box>
            {showResults === true && (
              <MenuCategory specs={{ name: 'Recomendado', protocolIds: query.map((p: any) => p.id) }} />
            )}
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

export default Recommender;
