import React, { FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import NavBar from './NavBar';
import MakanaBar from './AppBar';
import Home from '../screens/Home';
import BodyMapScreen from '../screens/BodyMap';
import Register from '../screens/Register';
import RegisterIn from '../screens/RegisterIn';
import RegisterOut from '../screens/RegisterOut';
import FastLogin from '../screens/FastLogin';
import RoutineScreen from '../screens/Routine';
import TermsAndConditions from '../screens/TermsAndConditions';
import RecommenderScreen from '../screens/Recommender';
import ScrollToTop from './ScrollToTop';
import AiExplain from '../screens/AiExplain';
import NordicScreen from '../screens/Nordic';
import CompanyLogin from '../components/LoginScreenComponents/CompanyLogin';

const myRoutes: FC = () => {
  const { token, redirect } = useSelector((state: RootState) => state.user);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <MakanaBar />
      <Routes>
        <Route path="/" element={token ? <Navigate to="/home" /> : <FastLogin />} />
        <Route path="/login" element={token ? <Navigate to="/home" /> : <FastLogin />} />
        <Route path="/business_login" element={<CompanyLogin />} />
        <Route path="/register" element={token ? <Navigate to="/home" /> : <Register />} />
        <Route path="/body" element={token ? <BodyMapScreen /> : <Navigate to="/login" />} />
        <Route path="/routine/:plan" element={token ? <RoutineScreen /> : <Navigate to="/login" />} />
        <Route path="/in/:plan" element={token && redirect ? <RegisterIn /> : <Navigate to="/login" />} />
        <Route path="/out/:plan" element={token && redirect ? <RegisterOut /> : <Navigate to="/login" />} />
        <Route path="/ai_explain" element={token ? <AiExplain /> : <Navigate to="/login" />} />
        <Route path="/nordic" element={<NordicScreen />} />
        <Route
          path="/home"
          element={
            redirect === 'terms' ? (
              <Navigate to="/terms_and_conditions" />
            ) : redirect === 'charlie' ? (
              <Navigate to="/charlie" />
            ) : redirect === 'nordic' ? (
              <Navigate to="/nordic" />
            ) : (
              <Home />
            )
          }
        />
        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
        <Route path="/charlie" element={<RecommenderScreen />} />
      </Routes>
      {token && <NavBar />}
    </Router>
  );
};

export default myRoutes;
